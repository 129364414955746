import styles from './Price.module.scss';
import { List } from 'dataProvider';
import React, { useCallback, useContext } from 'react';
import { rotateTimeToStr } from 'helpers/rotateTimeToStr';
import { countToStr } from 'helpers/countToStr';
import DataContext from '../../../contexts/DataContext';
import { useSnackbar } from 'notistack';

const IS_DEV = window.localStorage['IS_DEV'] === 't';
export const Price = ({ offer }: { offer: List }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { entryPrice, selectCount, rotateTime, id, name, src } = offer;

  const { dataProvider } = useContext(DataContext);

  const onPaySubmit = useCallback(
    (listId: string | number, direct?: boolean) => {
      dataProvider
        .listStart(listId, direct)
        .then((data = { url: '' }) => {
          if (data.url) {
            window.open(data.url, '_blank');
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(String(error), { variant: 'warning' });
        });
    },
    [dataProvider],
  );

  const onPayClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onPaySubmit(id);
  };

  const onPayDirectClick = (e: React.MouseEvent) => {
    e.preventDefault();
    location.replace(src);
    onPaySubmit(id, true);
  };

  const entryText = entryPrice > 0 ? 'Pay Now' : 'Entry';

  const titleText =
    entryPrice > 0
      ? `2 ${rotateTimeToStr(
          rotateTime,
        )} entry into $${name} Money List for ${entryPrice.toFixed(
          2,
        )} plus pay ${countToStr(
          selectCount,
        )} members ${name} each in Bitcoin Cryptocurrency`
      : `1 ${rotateTimeToStr(
          rotateTime,
        )} entry into $${name} Money List - just pay ${countToStr(
          selectCount,
        )} members ${name} each in Bitcoin Cryptocurrency`;

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p>{titleText}</p>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={onPayClick}>{entryText}</button>
        {IS_DEV ? (
          <button onClick={onPayDirectClick}>Pay Dev Now</button>
        ) : null}
      </div>
    </div>
  );
};
